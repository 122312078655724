.customGridColomnFilter {
  margin-top: 5px !important;
}

.productFilterMainView {
  width: 700px !important;
}

.customLabelFilter {
  margin-top: 5px !important;
}

.customButtonSearch {
  margin-top: 24px;
}

.productFilterCustomButton {
  margin-right: 20px !important;
}

.productFilterSortResultTitle {
  margin-bottom: 25px !important;
  font-size: 14px !important;
  font-family: "ProximaNova";
}

.customProductFilterMainView {
  background: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.salesUnitLink {
  color: var(--tableHeaderText) !important;
}

.salesUnitLink a {
  text-decoration: underline !important;
}

.productContentView {
  display: flex !important;
  align-items: center;
}

.productFilterButton {
  display: flex !important;
  justify-content: flex-end !important;
}

.productCustomBase {
  color: var(--mainColor);
  margin-left: 5px;
  font-weight: 800;
}

.productFilterTitle {
  font-family: "ProximaNova" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--mainTextInputHeader);
  margin-bottom: 10px !important;
}

.filterButton{
  margin-left: 15px !important;
}

@media screen and (max-width: 991px) {
  .customButtonSearch {
    margin-top: 0px;
  }

  .productFilterMainView {
    width: 300px !important;
  }
}